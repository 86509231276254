@font-face{
  font-family: "Futura LT W01 Medium";
  src: url("https://db.onlinewebfonts.com/t/77d394fa0c1103c648f880b77cb4a32f.eot");
  src: url("https://db.onlinewebfonts.com/t/77d394fa0c1103c648f880b77cb4a32f.eot?#iefix")format("embedded-opentype"),
      url("https://db.onlinewebfonts.com/t/77d394fa0c1103c648f880b77cb4a32f.woff")format("woff"),
      url("https://db.onlinewebfonts.com/t/77d394fa0c1103c648f880b77cb4a32f.woff2")format("woff2"),
      url("https://db.onlinewebfonts.com/t/77d394fa0c1103c648f880b77cb4a32f.ttf")format("truetype"),
      url("https://db.onlinewebfonts.com/t/77d394fa0c1103c648f880b77cb4a32f.svg#Futura LT W01 Medium")format("svg");
  font-weight:normal;
  font-style:normal;
  font-display:swap;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255,255,255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  max-width: 400px;
  border: 1px solid gray;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.4);
  background-color: white;
}

.modal-title {
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: large;
}

.modal-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-item {
  min-width: 320px;
  display: flex;
  justify-content: space-between;
}

.form-error {
  color: red;
}

input[type="text"], input[type="password"] {
  width: 100%;
  padding: 8px;
}

button {
  cursor: pointer;
  padding: 8px 16px;
}

button.close {
  background: none;
  border: none;
  padding: 0;

}

button.close::after {
  content: '✕';
  font-size: large;
  color: blue;
  cursor: pointer;
}
button.close:hover::after {
  color: cornflowerblue;
}

.top-bar {
  display: flex;
  padding: 16px;
}

.list-container {
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px;
}

.list-item {
  display: flex;
  gap: 8px;
  border-bottom: 1px solid lightblue;
  padding-bottom: 8px;
}

.list-item>span {
  padding: 8px 0;
}

.list-item>span:first-child {
  width: 100px;
}

.list-item>span:nth-child(2) {
  width: 100px;
  text-align: start;
}

.list-item>span:nth-child(3) {
  flex-grow: 1;
  text-align: start;
}

.button-wix {
  background: #000000;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 40px;
  padding: 0 14px;
  touch-action: manipulation;
  margin-top: 400px;
}

.button-text {
  margin: 0px 4px 0px 0px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  color: #FFFFFF;
  transition: background-color 0.3s ease;
}

.button-text:hover {
  color: #00BFFF;
}